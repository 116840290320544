// -- FIELDS
const _FIELDS = [
  {
    label: "name",
    type: "text",
    name: "nome",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  }
]

// -- HEADERS
const _HEADERS = [
  {
    label: "name",
    align: "left",
    value: "clientenome"
  },
  {
    label: "localidade",
    align: "left",
    value: "clientelocalidade"
  },
  {
    label: "actions",
    value: "action"
  }
]

const CLIENTE = {
  _FIELDS: _FIELDS,
  _HEADERS: _HEADERS
}

export default CLIENTE
