// -- FIELDS
const _FIELDS = [
  {
    label: "cliente",
    name: "codigo",
    type: "array",
    store: "cliente",
    value: "clientecodigo",
    text: "clientenome",
    size: {
      cols: 8,
      sm: 8,
      md: 8
    },
    rules: []
  },
  {
    label: "code",
    type: "text",
    name: "codigo",
    size: {
      cols: 4,
      sm: 4,
      md: 4
    },
    rules: []
  },
  {
    label: "name",
    type: "text",
    name: "nome",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  },
  {
    label: "localidade",
    type: "text",
    name: "localidade",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    label: "cp",
    type: "text",
    name: "cp",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    label: "morada",
    type: "text",
    name: "morada",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  },

  {
    label: "obs",
    type: "textarea",
    name: "obs",
    rows: "3",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: []
  }
]

// -- HEADERS
const _HEADERS = [
  {
    label: "id",
    align: "left",
    value: "id_obra"
  },
  {
    label: "cliente",
    align: "left",
    value: "nome"
  },
  {
    label: "morada",
    align: "left",
    value: "morada"
  },
  {
    label: "localidade",
    align: "left",
    value: "localidade"
  },
  {
    label: "status",
    align: "left",
    value: "estado"
  },
  {
    label: "actions",
    value: "action"
  }
]

const OBRA = {
  _FIELDS: _FIELDS,
  _HEADERS: _HEADERS
}

export default OBRA
