// -- FIELDS
const _FIELDS = [
  {
    label: "name",
    name: "nome",
    type: "text",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: ["required"]
  },
  {
    label: "username",
    name: "username",
    type: "text",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: ["required"]
  },
  {
    type: "password",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: []
  }
]

// -- HEADERS
const _HEADERS = [
  {
    label: "name",
    align: "left",
    value: "nome"
  },
  {
    label: "username",
    align: "left",
    value: "username"
  },
  {
    label: "status",
    align: "left",
    value: "estado"
  },
  {
    label: "actions",
    value: "action"
  }
]

const UTILIZADOR = {
  _FIELDS: _FIELDS,
  _HEADERS: _HEADERS
}

export default UTILIZADOR
