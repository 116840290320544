// -- FIELDS
const _FIELDS = [
  {
    label: "name",
    type: "text",
    name: "nome",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  },
  {
    label: "days",
    type: "text",
    name: "dias",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  }
]

// -- HEADERS
const _HEADERS = [
  {
    label: "name",
    align: "left",
    value: "nome"
  },
  {
    label: "days",
    align: "left",
    value: "dias"
  },
  {
    label: "actions",
    value: "action"
  }
]

const PRIORIDADE = {
  _FIELDS: _FIELDS,
  _HEADERS: _HEADERS
}

export default PRIORIDADE
