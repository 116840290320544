import UTILIZADOR from "@/store/schemas/utilizador"
import TIPO_AT from "@/store/schemas/tipo_at"
import PRIORIDADE from "@/store/schemas/prioridade"
import AT from "@/store/schemas/at"
import CLIENTE from "@/store/schemas/cliente"
import OBRA from "@/store/schemas/obra"

// -- ACTIONS
const _ACTIONS = {
  edit: {
    icon: "edit",
    color: "light-green darken-1",
    action: "edit"
  },
  delete: {
    icon: "delete",
    color: "red lighten-1",
    action: "delete"
  },
  orcamento: {
    icon: "mdi-clipboard-text-outline",
    color: "orange lighten-1",
    action: "orcamento"
  },
  orcamento_cliente: {
    icon: "mdi-clipboard-text-outline",
    color: "orange lighten-1",
    action: "orcamento_cliente"
  },
  intervencao: {
    icon: "mdi-hammer",
    color: "cyan",
    action: "intervencao"
  }
}

// -- SCHEMAS
const _MODELS = {
  UTILIZADOR: {
    _ACTIONS: [_ACTIONS.edit, _ACTIONS.delete],
    _FIELDS: UTILIZADOR._FIELDS,
    _HEADERS: UTILIZADOR._HEADERS
  },
  AT: {
    _ACTIONS: [
      _ACTIONS.intervencao,
      _ACTIONS.orcamento,
      _ACTIONS.edit,
      _ACTIONS.delete
    ],
    _FIELDS: AT._FIELDS,
    _HEADERS: AT._HEADERS
  },
  TIPO_AT: {
    _ACTIONS: [_ACTIONS.edit, _ACTIONS.delete],
    _FIELDS: TIPO_AT._FIELDS,
    _HEADERS: TIPO_AT._HEADERS
  },
  PRIORIDADE: {
    _ACTIONS: [_ACTIONS.edit, _ACTIONS.delete],
    _FIELDS: PRIORIDADE._FIELDS,
    _HEADERS: PRIORIDADE._HEADERS
  },
  CLIENTE: {
    _ACTIONS: [_ACTIONS.orcamento_cliente],
    _FIELDS: CLIENTE._FIELDS,
    _HEADERS: CLIENTE._HEADERS
  },
  OBRA: {
    _ACTIONS: [_ACTIONS.edit, _ACTIONS.delete],
    _FIELDS: OBRA._FIELDS,
    _HEADERS: OBRA._HEADERS
  }
}

export { _MODELS }
