// -- FIELDS
const _FIELDS = [
  {
    label: "cliente",
    name: "codigo",
    type: "array",
    store: "cliente",
    value: "clientecodigo",
    text: "clientenome",
    size: {
      cols: 8,
      sm: 8,
      md: 8
    },
    rules: []
  },
  {
    label: "code",
    type: "text",
    name: "codigo",
    size: {
      cols: 4,
      sm: 4,
      md: 4
    },
    rules: []
  },
  {
    label: "name",
    type: "text",
    name: "nome",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  },
  {
    label: "date",
    type: "data",
    name: "data",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    label: "hour",
    type: "time",
    name: "hora",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    label: "tipo_at",
    name: "id_tipo_at",
    type: "array",
    store: "tipo_at",
    selectMultiple:true,
    value: "id_tipo_at",
    text: "nome",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  },
  {
    label: "prioridade",
    name: "id_prioridade",
    type: "array",
    store: "prioridade",
    value: "id_prioridade",
    text: "nome",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: ["required"]
  },
  {
    label: "colaborador",
    name: "id_utilizador",
    type: "array",
    store: "colaborador",
    value: "id_utilizador",
    text: "nome",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    type: "orcamento",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },

  {
    label: "tlm",
    type: "text",
    name: "telemovel",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: ["required"]
  },
  {
    label: "email",
    type: "text",
    name: "email",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    label: "localidade",
    type: "text",
    name: "localidade",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    type: "dcl",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: ["required"]
  },

  {
    label: "cp",
    type: "text",
    name: "cp",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: []
  },
  {
    label: "morada",
    type: "text",
    name: "morada",
    size: {
      cols: 6,
      sm: 6,
      md: 6
    },
    rules: ["required"]
  },

  {
    label: "preco",
    type: "text",
    name: "preco",

    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: []
  },

  {
    label: "obs",
    type: "textarea",
    name: "obs",
    rows: "3",
    size: {
      cols: 12,
      sm: 12,
      md: 12
    },
    rules: []
  }
]

// -- HEADERS
const _HEADERS = [
  {
    label: "id",
    align: "left",
    value: "id_at"
  },
  {
    label: "cliente",
    align: "left",
    value: "nome"
  },
  {
    label: "tipo_at",
    align: "left",
    value: "tipos"
  },
  {
    label: "prioridade",
    align: "left",
    value: "prioridade"
  },
  {
    label: "data_criacao",
    align: "left",
    value: "created_at"
  },
  {
    label: "data_maximo",
    align: "left",
    value: "data_maximo"
  },
  {
    label: "data_agendamento",
    align: "left",
    value: "data_agendamento"
  },
  {
    label: "status",
    align: "left",
    value: "estado"
  },
  {
    label: "actions",
    value: "action"
  }
]

const AT = {
  _FIELDS: _FIELDS,
  _HEADERS: _HEADERS
}

export default AT
